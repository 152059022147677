import twinImage from '/assets/images/room-twin/room-twin-1.jpg';
import deluxeImage from '/assets/images/room-deluxe/room-deluxe-1.jpg';
import jakuziImage from '/assets/images/room-jakuzi/room-jakuzi-1.jpg';
import standartImage from '/assets/images/room-standart/room-standart-1.jpg';
import roomFamilyLuxury from '/assets/images/room-family-luxury/room-family-luxury-1.jpg';
import roomEconom from '/assets/images/room-econom/room-econom-1.jpg';
import roomComfort from '/assets/images/room-comfort/room-comfort-1.jpg';
import roomCoupe from '/assets/images/room-coupe/room-coupe-1.jpg';
import roomFamilyEconom from '/assets/images/room-family-econom/room-family-econom-1.jpg';
import roomBedComfort from '/assets/images/room-bed-comfort/room-bed-comfort-1.jpg';
import bedRoom6 from '/assets/images/six-bed-room/six-bed-room-1.jpg';
import bedRoom4 from '/assets/images/four-bed-room/four-bed-room-1.jpg';
import { BENEFITS, IBenefit } from '../data/benefits.ts';

export interface IRoom {
  id: number;
  title: string;
  text: string;
  square: number;
  priceDay: number;
  priceForTwoHours?: number;
  priceForExtraHour?: number;
  image: string;
  link: string;
  isRoomsHoursPage?: boolean;
}

export interface IRoomWithGalleryAndBenefits extends IRoom {
  gallery: string[];
  benefits: IBenefit[];
}

const ROOMS: IRoom[] = [
  {
    id: 1,
    square: 24,
    priceForTwoHours: 2000,
    priceForExtraHour: 500,
    priceDay: 3500,
    title: '2\u2011х местный "TWIN"',
    text: 'Двухместный номер с двумя раздельными кроватями, при желании кровати можно сдвигать. В нём есть все необходимое для полноценного комфортного отдыха. Наполнение каждого номера включает: 2 односпальные кровати; кондиционер; шкаф, столик; 2 стула; ЖК телевизор; телефонная связь; беспроводной интернет (Wi-Fi). В номере ванная комната, с необходимыми одноразовыми принадлежностями, набором полотенец.',
    image: twinImage,
    link: 'https://app.autoportye.ru/external_widget/v2/hotel/315?room_category_ids[]=1327',
  },
  {
    id: 2,
    square: 46,
    priceDay: 4000,
    priceForTwoHours: 2000,
    priceForExtraHour: 500,
    title: '2\u2011х местный "Делюкс"',
    text: 'Просторный номер, который оснащен 1 двуспальной кроватью, кондиционером, телевизором, душем и хорошей звукоизоляцией',
    image: deluxeImage,
    link: 'https://app.autoportye.ru/external_widget/v2/hotel/315?room_category_ids[]=1329',
  },
  {
    id: 3,
    square: 40,
    priceDay: 4500,
    priceForTwoHours: 2500,
    priceForExtraHour: 500,
    title: '3\u2011х местный "Люкс с джакузи"',
    text: 'Джакузи в номере — СПА. В номере 2-х спальная кровать Кинг сайз и односпальная кровать. Идеальное сочетание комфорта и функциональности. Наполнение номера включает: двухспальная кровать Кинг сайз; односпальная кровать; кондиционер; вешалка; столик; 2 стула; ЖК телевизор; телефонная связь; беспроводной интернет (Wi-Fi). В номере ванная комната, с необходимыми одноразовыми принадлежностями, набором полотенец.',
    image: jakuziImage,
    link: 'https://app.autoportye.ru/external_widget/v2/hotel/315?room_category_ids[]=1330',
  },
  {
    id: 4,
    square: 24,
    priceDay: 3500,
    priceForTwoHours: 2000,
    priceForExtraHour: 500,
    title: '2\u2011х местный "Стандарт"',
    text: 'Однокомнатный двухместный номер с большой двухспальной кроватью. В нём есть все необходимое для полноценного комфортного отдыха. Наполнение каждого номера включает: большая двухспальная кровать; кондиционер; шкаф, столик; 2 стула; ЖК телевизор; телефонная связь; беспроводной интернет (Wi-Fi); В номере ванная комната, с необходимыми одноразовыми принадлежностями, набором полотенец.',
    image: standartImage,
    link: 'https://app.autoportye.ru/external_widget/v2/hotel/315?room_category_ids[]=1328',
  },
  {
    id: 5,
    square: 40,
    priceDay: 4500,
    priceForTwoHours: 2500,
    priceForExtraHour: 500,
    title: 'Семейный Люкс',
    text: '2\u2011х комнатный номер повышенной комфортности с одной двуспальной кроватью 180х200 и раскладным диваном готов принять до 4х человек. (возможна установка раскладной кровати для 5-го человека). Идеальное сочетание комфорта и функциональности. Наполнение каждого номера включает: большая двухспальная кровать; раскладной диван; кондиционер; шкаф, столик; 4 стула; ЖК телевизор; телефонная связь; беспроводной интернет (Wi-Fi); В номере ванная комната, с необходимыми одноразовыми принадлежностями, набором полотенец.',
    image: roomFamilyLuxury,
    link: 'https://app.autoportye.ru/external_widget/v2/hotel/315?room_category_ids[]=1331',
  },
  {
    id: 6,
    square: 10,
    priceDay: 2500,
    title: '2\u2011х местный "Эконом"',
    text: `Двухместный номер с двумя раздельными кроватями.

      Санузел общий на этаже.
      
      В нём есть все необходимое для полноценного комфортного отдыха.
      
      Наполнение каждого номера включает: кондиционер; шкаф, столик; стулья; Телефонная связь; Беспроводной Интернет (Wi-Fi); одноразовые принадлежности, набор полотенец.
    `,
    image: roomEconom,
    link: 'https://app.autoportye.ru/external_widget/v2/hotel/315?room_category_ids[]=1332',
  },
  {
    id: 7,
    priceDay: 3000,
    square: 17,
    title: '3\u2011х местный "Комфорт"',
    text: `Трехместный номер с тремя односпальными кроватями.

      Санузел общий на этаже.
      
      В нём есть все необходимое для полноценного комфортного отдыха.
      
      Наполнение каждого номера включает: кондиционер; шкаф, столик; стулья; Телефонная связь; Беспроводной Интернет (Wi-Fi); одноразовые принадлежности, набор полотенец.
    `,
    image: roomComfort,
    link: 'https://app.autoportye.ru/external_widget/v2/hotel/315?room_category_ids[]=1333',
  },
  {
    id: 8,
    square: 6,
    priceDay: 2000,
    title: '2\u2011х местный "Купе"',
    text: `Двухместный номер с двухъярусной кроватью на двоих человек.

      В нём есть все необходимое для полноценного комфортного отдыха.
      
      Наполнение каждого номера включает: вешалку; столик; стулья; Телефонная связь; Беспроводной Интернет (Wi-Fi); одноразовые принадлежности, набор полотенец. Окно в коридор. Санузел общий на этаже.
    `,
    image: roomCoupe,
    link: 'https://app.autoportye.ru/external_widget/v2/hotel/315?room_category_ids[]=1334',
  },
  {
    id: 9,
    square: 17,
    priceDay: 3500,
    title: '4\u2011х местный "Семейный Эконом"',
    text: `Семейный номер с двухспальной и двухъярусной кроватью на четверых человек.

    В нём есть все необходимое для полноценного комфортного отдыха.
    
    Наполнение каждого номера включает: кондиционер; шкаф, столик; стулья; Телефонная связь; Беспроводной Интернет (Wi-Fi); одноразовые принадлежности, набор полотенец. Санузел общий на этаже.`,
    image: roomFamilyEconom,
    link: 'https://app.autoportye.ru/external_widget/v2/hotel/315?room_category_ids[]=1335',
  },
  {
    id: 10,
    square: 20,
    priceDay: 750,
    title: 'Односпальная кровать в мужском номере "Комфорт"',
    text: `Номер для комфортного проживания 4 человек. В нём есть все необходимое для полноценного комфортного отдыха. Наполнение каждого номера включает: односпальные кровати с ортопедическими матрасами кондиционер; шкаф, столик; стулья; Телефонная связь; Беспроводной Интернет (Wi-Fi); одноразовые принадлежности, набор полотенец. Санузел и кухня общие на этаже.`,
    image: roomBedComfort,
    link: 'https://app.autoportye.ru/external_widget/v2/hotel/315?room_category_ids[]=1336',
  },
  {
    id: 11,
    square: 20,
    priceDay: 650,
    title: '"Койко-место" в мужском 6\u2011ти местном номере',
    text: `Шестиместный общий номер с  двухъярусными кроватями с ортопедическими матрасами. 
    В нём есть все необходимое для полноценного комфортного отдыха.
    Наполнение каждого номера включает:  кондиционер; шкаф; столик;  стулья;  Телефонная связь; Беспроводной Интернет (Wi-Fi); одноразовые принадлежности, набор полотенец. Санузел и кухня общие на этаже.`,
    image: bedRoom6,
    link: 'https://app.autoportye.ru/external_widget/v2/hotel/315?room_category_ids[]=1337',
  },
  {
    id: 12,
    square: 20,
    priceDay: 650,
    title: '"Койко-место" в женском 4\u2011x местном номере',
    text: `Четырехместный общий номер с  двухъярусными кроватями с ортопедическими матрасами. 
    В нём есть все необходимое для полноценного комфортного отдыха.
    Наполнение каждого номера включает:  кондиционер; шкаф; столик;  стулья;  Телефонная связь; Беспроводной Интернет (Wi-Fi); одноразовые принадлежности, набор полотенец. Санузел и кухня общие на этаже.`,
    image: bedRoom4,
    link: 'https://app.autoportye.ru/external_widget/v2/hotel/315?room_category_ids[]=1338',
  },
];

const BASE_URL = '/assets/images/';

const createArray = (baseUrl: string, count: number) => {
  const arr = [];

  for (let i = 0; i < count; i++) {
    arr.push(`${baseUrl}${i + 1}.jpg`);
  }

  return arr;
};

const GALLERY: Record<number, string[]> = {
  1: createArray(`${BASE_URL}room-twin/room-twin-`, 6),
  2: createArray(`${BASE_URL}room-deluxe/room-deluxe-`, 6),
  3: createArray(`${BASE_URL}room-jakuzi/room-jakuzi-`, 6),
  4: createArray(`${BASE_URL}room-standart/room-standart-`, 10),
  5: createArray(`${BASE_URL}room-family-luxury/room-family-luxury-`, 8),
  6: createArray(`${BASE_URL}room-econom/room-econom-`, 5),
  7: createArray(`${BASE_URL}room-comfort/room-comfort-`, 7),
  8: createArray(`${BASE_URL}room-coupe/room-coupe-`, 5),
  9: createArray(`${BASE_URL}room-family-econom/room-family-econom-`, 6),
  10: createArray(`${BASE_URL}room-bed-comfort/room-bed-comfort-`, 6),
  11: createArray(`${BASE_URL}six-bed-room/six-bed-room-`, 6),
  12: createArray(`${BASE_URL}four-bed-room/four-bed-room-`, 5),
};

const ROOMS_BENEFITS: Record<number, IBenefit[]> = {
  1: [
    BENEFITS.OWNED_BATHROOM,
    BENEFITS.CONDITIONER,
    BENEFITS.TOILETRIES,
    BENEFITS.IRONING_ACCESSORIES,
    BENEFITS.TWO_SINGLE_BEDS,
    BENEFITS.SLIPPERS,
    BENEFITS.DESK,
    BENEFITS.TV,
    BENEFITS.CLOSET,
  ],
  2: [
    BENEFITS.OWNED_BATHROOM,
    BENEFITS.SHOWER,
    BENEFITS.CONDITIONER,
    BENEFITS.TOILETRIES,
    BENEFITS.IRONING_ACCESSORIES,
    BENEFITS.DOUBLE_BED,
    BENEFITS.SLIPPERS,
    BENEFITS.DESK,
    BENEFITS.TV,
  ],
  3: [
    BENEFITS.OWNED_BATHROOM,
    BENEFITS.CONDITIONER,
    BENEFITS.TOILETRIES,
    BENEFITS.IRONING_ACCESSORIES,
    BENEFITS.DOUBLE_BED,
    BENEFITS.SLIPPERS,
    BENEFITS.DESK,
    BENEFITS.TV,
  ],
  4: [
    BENEFITS.OWNED_BATHROOM,
    BENEFITS.SHOWER,
    BENEFITS.CONDITIONER,
    BENEFITS.TOILETRIES,
    BENEFITS.IRONING_ACCESSORIES,
    BENEFITS.DOUBLE_BED,
    BENEFITS.SLIPPERS,
    BENEFITS.DESK,
    BENEFITS.TV,
  ],
  5: [
    BENEFITS.OWNED_BATHROOM,
    BENEFITS.SHOWER,
    BENEFITS.SAFE,
    BENEFITS.CONDITIONER,
    BENEFITS.TOILETRIES,
    BENEFITS.IRONING_ACCESSORIES,
    BENEFITS.TELEPHONE,
    BENEFITS.SOFA,
    BENEFITS.DOUBLE_BED,
    BENEFITS.SLIPPERS,
    BENEFITS.DESK,
    BENEFITS.CLOSET,
    BENEFITS.TV,
  ],
  6: [
    BENEFITS.SHARED_BATHROOM,
    BENEFITS.CONDITIONER,
    BENEFITS.BOTTLED_WATER,
    BENEFITS.TOILETRIES,
    BENEFITS.IRONING_ACCESSORIES,
    BENEFITS.CLOSET,
    BENEFITS.DESK,
    BENEFITS.TWO_SINGLE_BEDS,
  ],
  7: [
    BENEFITS.SHARED_BATHROOM,
    BENEFITS.CONDITIONER,
    BENEFITS.BOTTLED_WATER,
    BENEFITS.TOILETRIES,
    BENEFITS.IRONING_ACCESSORIES,
    BENEFITS.CLOSET,
    BENEFITS.DESK,
    BENEFITS.DIFFERENT_TYPES_OF_BEDS,
  ],
  8: [
    BENEFITS.SHARED_BATHROOM,
    BENEFITS.CONDITIONER,
    BENEFITS.BOTTLED_WATER,
    BENEFITS.TOILETRIES,
    BENEFITS.IRONING_ACCESSORIES,
    BENEFITS.CLOSET,
    BENEFITS.DESK,
    BENEFITS.BUNK_BED,
  ],
  9: [
    BENEFITS.SHARED_BATHROOM,
    BENEFITS.CONDITIONER,
    BENEFITS.BOTTLED_WATER,
    BENEFITS.TOILETRIES,
    BENEFITS.IRONING_ACCESSORIES,
    BENEFITS.CLOSET,
    BENEFITS.DESK,
    BENEFITS.DIFFERENT_TYPES_OF_BEDS,
  ],
  10: [
    BENEFITS.SHARED_BATHROOM,
    BENEFITS.CONDITIONER,
    BENEFITS.BOTTLED_WATER,
    BENEFITS.TOILETRIES,
    BENEFITS.IRONING_ACCESSORIES,
    BENEFITS.CLOSET,
    BENEFITS.DESK,
    BENEFITS.DIFFERENT_TYPES_OF_BEDS,
  ],
  11: [
    BENEFITS.SHARED_BATHROOM,
    BENEFITS.CONDITIONER,
    BENEFITS.BOTTLED_WATER,
    BENEFITS.TOILETRIES,
    BENEFITS.IRONING_ACCESSORIES,
    BENEFITS.CLOSET,
    BENEFITS.DESK,
    BENEFITS.DIFFERENT_TYPES_OF_BEDS,
  ],
  12: [
    BENEFITS.SHARED_BATHROOM,
    BENEFITS.CONDITIONER,
    BENEFITS.BOTTLED_WATER,
    BENEFITS.TOILETRIES,
    BENEFITS.IRONING_ACCESSORIES,
    BENEFITS.CLOSET,
    BENEFITS.DESK,
    BENEFITS.DIFFERENT_TYPES_OF_BEDS,
  ],
};

export class RoomsService {
  getRooms(): Promise<IRoom[]> {
    return Promise.resolve(ROOMS);
  }

  getHoursRooms(): Promise<IRoom[]> {
    const filteredRooms = ROOMS.filter((room) => room.priceForTwoHours);

    return Promise.resolve(filteredRooms);
  }

  getRoom(id: number): Promise<IRoomWithGalleryAndBenefits> {
    const filteredRooms = ROOMS.filter((room) => room.id === id);

    if (filteredRooms.length === 0) {
      return Promise.reject('Такого номера не существует');
    }

    const room: IRoomWithGalleryAndBenefits = {
      ...filteredRooms[0],
      gallery: GALLERY[id],
      benefits: ROOMS_BENEFITS[id],
    };

    return Promise.resolve(room);
  }
}
